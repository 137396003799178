.gmap {
  height: 200px;
}
.result-area {
  min-height: 100px;
  max-height: 400px;
}
.address-pending {
  font-size: .8em;
}

.map-wrapper {
  position: relative;
}

.center-cross {
  pointer-events: none;
  position: absolute;
  z-index: 3;
  width: 60px;
  height: 60px;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  opacity:0.5;
  &::before, &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-top: 2px solid black;
    transform: translateX(-50%);
  }
  &::after {
    top: 0px;
    left: -50%;
    transform: rotate(90deg);
  }
}
