@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/bootstrap";

// color
$color-white:#ffffff;
$color-gray:#ccc;
$color-black:#333333;

// layout
$width: 1000px;
$sp-width: 767.98px;


// body {
//   font-size: .875rem;
// }

main {
  padding-bottom: 40px;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  @media (max-width: $sp-width) {
    top: 5rem;
  }
  &-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  .nav{
    &-link {
      font-weight: 500;
      color: $color-black;
      .feather {
        margin-right: 4px;
        color: #727272;
      }
      &.active,&:hover{
        color: #fff;
        background-color:#138BB0 ;
        .feather{
          color: inherit;
        }
      }
    }
  }
  &-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  .sidebar-logo-container {
    position: absolute;
    top: auto;
    bottom: 0;
    text-align: center;
    flex: none;
    margin: 20px 20px 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 160px;
  }
}




/*
 * Navbar
 */

.navbar-brand {
  font-size: 1rem;
      height: 100%;
    padding: 8px 10px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  @media (max-width: $sp-width) {
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.logo{
    width: 68%;
    max-width: 200px;
    margin: 0 auto;
    display: inline-block;
}


.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.admin-footer {
  z-index: 1050;
}



.header{
    background: linear-gradient(90.52deg, #138BB0 0.77%, #AFDAD4 100.77%);
    color: #333333;
}





.title{
  &-h6{
    background-color: #6a6a6a;
    color: #fff !important;
    padding: 8px;
    margin: 0;
    color: #fff !important;
    font-weight: bold;
  }
}


h2{
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
  padding: 1rem 0;
  border-bottom: 2px solid #AFDAD4;
}

h2:before {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 20%;
  height: 2px;
  content: '';
  background: #138BB0;
}

h3 {
  margin: 30px 0 20px 0;
}


label{
  margin-bottom: 10px;
}

a {
  color: #cc9310;
  text-decoration: underline;
}

.btn-primary {
  background-color: #F5CE68 !important;
  border: none !important;
  color: #333 !important;
  border-radius: 20px !important;
  padding: 8px 20px !important;
}

.plan-label {
  padding: 4px;
}

.admin-footer {
  margin-top: 40px;
}

.table-bordered th,
.table-bordered td {
  padding: 8px;
}
